import { Logo } from '@/components/ui/Logo';
import { project } from '@/lib/project';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Link from 'next/link';
import { i18nRoute } from 'routes';

export default function Page() {
	const { t } = useTranslation(['common']);
	return (
		<div className="bg-white">
			<main className="mx-auto w-full max-w-7xl px-4 pb-16 pt-10 sm:pb-24 lg:px-8">
				<Logo className="mx-auto h-10 w-auto" />
				<div className="mx-auto mt-20 max-w-2xl text-center sm:mt-24">
					<p className="text-base font-semibold leading-8 text-blue-600">404</p>
					<h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
						{t('common:404.title')}
					</h1>
					<p className="mt-4 text-base leading-7 text-gray-600 sm:mt-6 sm:text-lg sm:leading-8">
						{t('common:404.subtitle')}
					</p>
				</div>
				<div className="mx-auto mt-16 flow-root max-w-lg sm:mt-20">
					<h2 className="sr-only">Popular pages</h2>
					<div className="mt-10 flex justify-center">
						<Link
							href={i18nRoute('/', 'en')}
							className="text-sm font-semibold leading-6 text-blue-600"
						>
							<span aria-hidden="true">&larr;</span>
							{t('common:404.backHome')}
						</Link>
					</div>
				</div>
			</main>
			<footer className="border-t border-gray-100 py-6 sm:py-10">
				<div className="mx-auto flex max-w-7xl flex-col items-center justify-center gap-8 px-6 sm:flex-row lg:px-8">
					<p className="text-sm leading-7 text-gray-400">
						{t('common:footer.copyright', {
							date: new Date().getFullYear(),
							project: project.name,
						})}
					</p>
				</div>
			</footer>
		</div>
	);
}

export async function getStaticProps({ locale }) {
	return {
		props: {
			locale: locale,
			disableHeader: true,
			disableFooter: true,
			...(await serverSideTranslations(locale, ['common'])),
		},
	};
}
